import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from "./router"
import store from "./store"
import '../node_modules/flowbite-vue/dist/index.css'
import VueMeta from 'vue-meta'
import VueCryptojs from 'vue-cryptojs'

createApp(App)
    .use(store)
    .use(router)
    .use(VueMeta)
    .use(VueCryptojs)
    .mount('#app')
