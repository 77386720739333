import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://shedule-server.dz72.ru/api/data/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + '1.2.655.5.2.13.16.12.2.72.3452');
  }

  getUserBoard(OID_MO) {
    return axios.get(API_URL + OID_MO, { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'board/admin', { headers: authHeader() });
  }

  getAdminData() {
    return axios.get(API_URL + 'board/data', { headers: authHeader() });
  }

  getUpdateData(oid) {
    return axios.post(API_URL + `board/data/update`, {oid: oid}, {headers: authHeader()
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error
    });
  }

  getUserDelete(user) {
    return axios.post(API_URL + `user/delete/${user}`,
      {
        id: user
      }, { headers: authHeader() }
    ).then(response => {
      return response.data;
    });
  }

  getUserUpdate(user) {
    let data = user
    for (let key in data) {
      if (data[key] == '') {
        delete data[key]
      }
    }
    return axios.post(API_URL + `user/update/${data.id}`,
      {
        id: data.id,
        username: data.username,
        medorg: data.medorg,
        medoid: data.medoid,
        password: data.password
      }, { headers: authHeader() }
    ).then(response => {
      return response.data;
    }).catch(error => {
      return error
    });
  }
}

export default new UserService();