import { createWebHistory, createRouter } from "vue-router";
import Profile from "./components/Profile.vue";
import Login from "./components/Login.vue";
// lazy-loaded
// const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const BoardData = () => import("./components/BoardData.vue")
const BoardFAQ = () => import("./components/FAQ.vue")

const routes = [
  {
    path: "/",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    component: BoardAdmin,
  },
  {
    path: "/data",
    name: "data",
    component: BoardData,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
    meta: {
      reload: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: BoardFAQ,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  
  watch:{
    '$route' (to) {
       if(to.currentRoute.meta.reload==true){window.location.reload()}
  }
}

});

export default router;