<template>
  <div class="container-fluid">
    <div v-if="currentDataUser" class="profile">
      <div class="col-user col-ud">
        <header class="jumbotron">
          <h3>
            <strong>{{ currentDataUser.medorg }}</strong>
            <p>{{ currentDataUser.medoid }} <span v-if="message" style="color: red;">{{ message }}</span></p>
          </h3>
        </header>
        <p>
          <strong>Имя пользователя:</strong>
          {{ currentDataUser.username }}
        </p>
        <div v-for="role in currentDataUser.roles" :key="role">
          <p v-if="role === 'ROLE_USER'"><strong>Роль в системе:</strong> Пользователь</p>
          <p v-else><strong>Роль в системе:</strong> Администратор</p>
        </div>
      </div>

      <div class="col-user col-ud">
        <div class="settings_mo">
          <div class="card-header">
            <h5>Настройка цветовой схемы</h5>
          </div>

          <div class="theme-box">
            <div class="styled-input-single styled-input--square" v-for="(theme, index) in arrayTheme" :key="index">
              <input @change="changeTheme($event)" :id="theme.name" class="mr-2" type="radio" v-model="userTheme"
                :value="theme.name" />
              <label :for="theme.name">{{ theme.name_p }}</label>
            </div>
          </div>

        </div>
      </div>

      <div class="col-user col-ud">
        <div class="settings_mo">
          <div class="card-header">
            <h5>Настройка размера шрифта</h5>
          </div>

          <div class="theme-box">
            <div class="styled-input-single styled-input--square" v-for="(theme, index) in arrayFont" :key="index">
              <input @change="changeFont($event)" :id="theme.name" class="mr-2" type="radio" v-model="fontTheme"
                :value="theme.name" />
              <label :for="theme.name">{{ theme.name_p }}</label>
            </div>
          </div>

        </div>
      </div>

      <div v-if="visible" class="col-department col-ud">
        <div class="settings_mo">
          <div class="card-header">
            <h5>Настройка отображения отделений</h5>
          </div>

          <div v-if="departmentsLocalStorage.length == 0">
            <strong>Выберите отделения для показа:</strong>

            <circles-to-rhombuses-spinner :animation-duration="1000" :size="40" style="margin-top:25px;" color="#00569C" v-if="loading"/>

            <div class="styled-input-single styled-input--square" v-for="(dep, index) in content" :key="index">
              <input :id="dep.oid" class="mr-2" type="checkbox" v-model="departments" :value="dep" />
              <label :for="dep.oid">{{ dep.name }} - {{ dep.address }}</label>
            </div>
            <button v-if="departments.length != 0" class="addDep" @click="addDepartament">Сохранить</button>
          </div>

          <div v-else>
            <strong>Выбранные отделения:</strong>
            <div class="styled-list-single">
              <ul>
                <li v-for="(depo, index) in departmentsLocalStorage" :key="index">{{ depo.name }} - {{ depo.address }}
                </li>
              </ul>
            </div>
            <button class="addDep" @click="deleteDepartament">Сбросить настройки</button>
          </div>
        </div>
      </div>

      <div v-if="visible" class="col-department col-ud">
        <div class="settings_mo">
          <div class="card-header">
            <h5>Настройка отображения специалистов</h5>
          </div>

          <strong>Выберите количество для показа (по умолчанию 10):</strong>
          <div class="styled-input-select">
            <select v-model="numDoc" @change="onChange($event)">
              <option v-for="numDoc in [3, 4, 5, 6, 7, 8, 9, 10]" :key="numDoc" v-bind:value="numDoc">{{ numDoc }}
              </option>
            </select>
          </div>
          <strong>Выберите интервал времени перехода в секундах (по умолчанию 30 сек):</strong>
          <div class="styled-input-select">
            <select v-model="intervalSlider" @change="onChangeInterval($event)">
              <option v-for="intervalSlider in [30000, 60000, 120000]" :key="intervalSlider"
                v-bind:value="intervalSlider">{{ intervalSlider / 1000 }}</option>
            </select>
          </div>
          <!-- <button class="addDep" @click="deleteNumDoc">По умолчанию</button> -->
        </div>
      </div>

      <div v-if="visible" class="col-department col-ud">
        <div class="settings_mo">
          <div class="card-header">
            <h5>Веб приложение для сайта</h5>
          </div>
          <div><a href="">Скачать инструкцию</a></div>
          <div><a href="">Скачать приложение</a></div>
        </div>
      </div>

    </div>
  </div>
</template>
    
<script>
import UserService from "../services/user.service";
import { mapActions } from 'vuex'
import { CirclesToRhombusesSpinner } from 'epic-spinners'

export default {
  name: 'ProfileBoard',
  data() {
    return {
      arrayTheme: [
        { "name_p": "Светлая тема", "name": "light-theme" },
        { "name_p": "Темная тема", "name": "dark-theme" },
        { "name_p": "Детская тема", "name": "kids-theme" }
      ],
      userTheme: (localStorage.getItem("user-theme") != null || undefined) ? localStorage.getItem("user-theme") : "light-theme",
      arrayFont: [
        { "name_p": "Нормальный", "name": "normal" },
        { "name_p": "Большой", "name": "big" },
        { "name_p": "Очень большой", "name": "veryBig" }
      ],
      fontTheme: (localStorage.getItem("font-theme") != null || undefined) ? localStorage.getItem("font-theme") : "normal",
      kidsTheme: false,
      content: [],
      departments: [],
      departmentsLocalStorage: [],
      visible: true,
      message: "",
      numDoc: 5,
      intervalSlider: 30000,
      loading: true
    };
  },
  components: {
    CirclesToRhombusesSpinner
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentDataUser() {
      return this.$store.state.auth.user;
    },
    inSecondtoMinutes(value) {
      if (value < 60) {
        return value
      }
      return value / 60
    }
  },
  methods: {
    ...mapActions("getData", ["DepartmentList", "ThemeKids", "ThemeFont"]),
    onChange(event) {
      sessionStorage.setItem('number_doctor', event.target.value)
    },
    onChangeInterval(event) {
      sessionStorage.setItem('interval_slider', event.target.value)
    },
    deleteNumDoc: function () {
      this.numDoc = 5
      this.intervalSlider = 1
      sessionStorage.removeItem('number_doctor');
      sessionStorage.removeItem('interval_slider');
    },
    addDepartament: function () {
      sessionStorage.removeItem('departaments');
      let parsed = this.departments;
      this.departments = sessionStorage.setItem('departaments', JSON.stringify(parsed));
      this.getDepartment();
    },
    deleteDepartament: function () {
      this.content = [];
      this.departments = [];
      this.departmentsLocalStorage = [];
      sessionStorage.removeItem('departaments');
      this.getDepartment();
    },
    getDepartment: function () {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user.medoid) {
        this.visible = false
        sessionStorage.removeItem('departaments');
        return
      }
      else if (sessionStorage.getItem("departaments") === null) {

        UserService.getUserBoard(user.medoid).then(
          (response) => {

            const content = response.data[0].departments;

            content.forEach(value => {
              const departments = {
                name: value.name,
                oid: value.oid,
                address: value.address
              }
              this.content.push(departments)
            });
            this.loading = false
          },
          (error) => {
            if (error) {
              this.visible = false
              this.message = "не удалось найти oid"
              this.$store.state.auth.status.loggedIn = false
              this.$router.push('/login');
            }
          }
        );
      } else {
        this.departmentsLocalStorage = JSON.parse(sessionStorage.getItem('departaments'));
      }
    },
    changeTheme(event) {
      localStorage.setItem("user-theme", event.target.value)
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "dark-theme") {
        this.setTheme("dark-theme");
        this.ThemeKids("dark-theme");
      } else if (activeTheme === "kids-theme") {
        this.setTheme("kids-theme");
        this.ThemeKids("kids-theme");
      } else {
        this.setTheme("light-theme");
        this.ThemeKids("light-theme");
      }
    },
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    getTheme() {
      if (localStorage.getItem("user-theme") === null || undefined) {
        localStorage.setItem("user-theme", "light-theme");
      }
      return localStorage.getItem("user-theme");
    },
    changeFont(event) {
      localStorage.setItem("font-theme", event.target.value)
      const activeFont = localStorage.getItem("font-theme");
      if (activeFont === "big") {
        this.setFont("big");
        this.ThemeFont("big")
      } else if (activeFont === "veryBig") {
        this.setFont("veryBig");
        this.ThemeFont("veryBig")
      } else {
        this.setFont("normal");
        this.ThemeFont("normal")
      }
    },
    setFont(font) {
      localStorage.setItem("font-theme", font);
      this.fontTheme = font;
      const el = document.getElementById("app");
      el.className = font;

    },
    getFont() {
      if (localStorage.getItem("font-theme") === null || undefined) {
        localStorage.setItem("font-theme", "normal");
      }
      return localStorage.getItem("font-theme");
    },
  },
  mounted() {
    document.title = 'Настройки';
        
    const initUserTheme = this.getTheme() || this.toggleKidsTheme();
    this.setTheme(initUserTheme);

    if (localStorage.getItem("user-theme") === "kids-theme") {
      this.kidsTheme = true
    }

    const initUserFont = this.getFont();
    this.setFont(initUserFont);

    if (!this.currentUser || !this.currentDataUser) {
      localStorage.removeItem('user')
      this.$router.push('/login');
      
    } else {
      this.getDepartment()
      
    }

    if (sessionStorage.getItem("number_doctor") != null) {
      this.numDoc = sessionStorage.getItem("number_doctor")
    } else {
      sessionStorage.setItem("number_doctor", this.numDoc)
    }

    if (sessionStorage.getItem("interval_slider") != null) {
      this.intervalSlider = sessionStorage.getItem("interval_slider")
    }
  }
};
</script>