import UserService from "../services/user.service";

export const getData = {
    namespaced: true,
    state() {
        return {
            content: [],
            departament: [],
            themeKids: (localStorage.getItem("user-theme") === "kids-theme") ? true : false,
            themeFont: (localStorage.getItem("font-theme") != null) ? localStorage.getItem("font-theme") : localStorage.setItem("font-theme", "normal"),
        }
    },
    actions: {
        async CheckedDataSession({ commit }) {
            const user = JSON.parse(localStorage.getItem('user'));
            await UserService.getUserBoard(user.medoid).then(
                (response) => {
                    const content = response.data[0].departments
                    commit('updateContent', content)
                },
                (error) => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        async DepartmentList({ commit }) {
            if (sessionStorage.getItem("departaments") != null || undefined) {
                const oid = []
                const departaments = JSON.parse(sessionStorage.getItem('departaments'))
                await departaments.forEach(val => {
                    oid.push(val.oid)
                })

                commit('updateDepartament', oid)
            }
        },
        async ThemeKids(ctx, data) {
            if (data === "kids-theme") {
                ctx.commit('updateThemeKids', true)
            } else {
                ctx.commit('updateThemeKids', false)
            }  
        },
        async ThemeFont(ctx, data) {
                ctx.commit('updateThemeFont', data)
        },
    },
    mutations: {
        updateContent(state, content) {
            state.content = content
        },
        updateDepartament(state, departament) {
            state.departament = departament
        },
        updateThemeKids(state, themeKids) {
            state.themeKids = themeKids
        },
        updateThemeFont(state, themeFont) {
            state.themeFont = themeFont
        }
    },
    getters: {
        getAllContent(state) {
            return state.content
        },
        getDepartament(state) {
            return state.departament
        },
        getFilteredList(state) {
            return state.content
                .filter(content => {
                    return !state.departament.length || state.departament.some((n) => content.oid.includes(n))
                })
        },
        getThemeKids(state) {
            return state.themeKids
        },
        getThemeFont(state) {
            return state.themeFont
        }
    }
};