<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="navbar-nav ml-auto">
      <b>{{ medorg }}</b>
    </div>
    <div v-if="!currentUser" class="navbar-nav mr-auto">
      <router-link to="/login" class="nav-link">
        <!-- Войти -->
        <svg width="800px" height="800px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>Войти</title>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7 1L14 1V14H7V13H13V2L7 2V1ZM7.85355 4.14645L11.1932 7.48614L7.8674 11.0891L7.13259 10.4109L9.358 8L0.999996 8V7L9.29289 7L7.14644 4.85355L7.85355 4.14645Z"
            fill="#000000" />
        </svg>
      </router-link>
    </div>

    <div v-if="currentUser" class="navbar-nav mr-auto">
      <div v-if="showAdminBoard" class="nav-item">
        <router-link to="/admin" class="nav-link">Администрирование</router-link>
      </div>
      <div v-if="showAdminBoard" class="nav-item">
        <router-link to="/data" class="nav-link">Данные</router-link>
      </div>
      <div v-if="showUserBoard" class="nav-item">
        <router-link v-if="currentUser" to="/user" class="nav-link">Дашборд</router-link>
      </div>
      <router-link to="/profile" class="nav-link">
        <svg width="800px" height="800px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" class="si-glyph si-glyph-gear-1">
          <title>Настройки</title>
          <g stroke-width="1">
            <g transform="translate(1.000000, 1.000000)">
              <path
                d="M7.887,9.025 C7.799,8.449 7.569,7.92 7.229,7.475 L7.995,6.71 L7.307,6.023 L6.536,6.794 C6.093,6.467 5.566,6.245 4.994,6.161 L4.994,5.066 L4.021,5.066 L4.021,6.155 C3.444,6.232 2.913,6.452 2.461,6.777 L1.709,6.024 L1.021,6.712 L1.761,7.452 C1.411,7.901 1.175,8.437 1.087,9.024 L0.062,9.024 L0.062,9.025 L0.062,9.998 L1.08,9.998 C1.162,10.589 1.396,11.132 1.744,11.587 L1.02,12.31 L1.708,12.997 L2.437,12.268 C2.892,12.604 3.432,12.83 4.02,12.91 L4.02,13.958 L4.993,13.958 L4.993,12.904 C5.576,12.818 6.11,12.589 6.56,12.252 L7.306,12.999 L7.994,12.311 L7.248,11.564 C7.586,11.115 7.812,10.581 7.893,10 L8.952,10 L8.952,9.998 L8.952,9.026 L7.887,9.026 L7.887,9.025 Z M4.496,11.295 C3.512,11.295 2.715,10.497 2.715,9.512 C2.715,8.528 3.512,7.73 4.496,7.73 C5.481,7.73 6.28,8.528 6.28,9.512 C6.28,10.497 5.481,11.295 4.496,11.295 L4.496,11.295 Z"
                class="si-glyph-fill">
              </path>
              <path
                d="M13.031,3.37 L14.121,3.089 L13.869,2.11 L12.778,2.392 C12.66,2.152 12.513,1.922 12.317,1.72 C12.125,1.524 11.902,1.376 11.67,1.256 L11.971,0.177 L10.998,-0.094 L10.699,0.978 C10.158,0.935 9.608,1.056 9.133,1.36 L8.373,0.584 L7.652,1.291 L8.408,2.061 C8.082,2.531 7.939,3.085 7.967,3.636 L6.927,3.904 L7.179,4.881 L8.217,4.613 C8.334,4.856 8.483,5.088 8.682,5.291 C8.885,5.499 9.121,5.653 9.368,5.776 L9.079,6.815 L10.05,7.086 L10.343,6.038 C10.885,6.071 11.435,5.938 11.906,5.623 L12.677,6.409 L13.397,5.702 L12.621,4.911 C12.928,4.446 13.06,3.905 13.031,3.37 L13.031,3.37 Z M10.514,4.987 C9.691,4.987 9.023,4.318 9.023,3.494 C9.023,2.672 9.691,2.005 10.514,2.005 C11.336,2.005 12.004,2.672 12.004,3.494 C12.004,4.318 11.336,4.987 10.514,4.987 L10.514,4.987 Z"
                class="si-glyph-fill">
              </path>
            </g>
          </g>
        </svg>
      </router-link>
      <router-link to="/faq" class="nav-link">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 1024 1024"
          class="si-glyph si-glyph-gear-1">
          <title>О програме</title>
          <path
            d="M512 277.333c-58.974 0-106.667 47.693-106.667 106.667 0 11.782-9.551 21.333-21.333 21.333s-21.333-9.551-21.333-21.333c0-82.538 66.795-149.333 149.333-149.333S661.333 301.463 661.333 384c0 75.294-55.586 137.489-128 147.823V640c0 11.78-9.553 21.333-21.333 21.333S490.667 651.78 490.667 640V512c0-11.78 9.553-21.333 21.333-21.333 58.974 0 106.667-47.693 106.667-106.667S570.974 277.333 512 277.333zm0 506.454c23.565 0 42.667-19.102 42.667-42.667S535.565 698.453 512 698.453s-42.667 19.102-42.667 42.667 19.102 42.667 42.667 42.667z" />
          <path
            d="M512 85.333C276.358 85.333 85.333 276.358 85.333 512c0 235.639 191.025 426.667 426.667 426.667 235.639 0 426.667-191.027 426.667-426.667C938.667 276.358 747.64 85.333 512 85.333zM128 512c0-212.077 171.923-384 384-384 212.079 0 384 171.923 384 384 0 212.079-171.921 384-384 384-212.077 0-384-171.921-384-384z" />
        </svg>
      </router-link>
      <a class="nav-link" @click.prevent="fullscreenEnabled">
        <svg width="800px" height="800px" viewBox="0 -0.5 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" class="si-glyph si-glyph-square-dashed-2">
          <title>На весь экран</title>
          <g stroke-width="1">
            <g>
              <path d="M10,0.834 L15.083,0.834 L15.083,5.875 L15.917,5.875 L15.917,0 L10,0 L10,0.834 Z"
                class="si-glyph-fill"></path>
              <path d="M0.834,5.875 L0.834,0.834 L5.917,0.834 L5.917,0 L0,0 L0,5.875 L0.834,5.875 Z"
                class="si-glyph-fill"></path>
              <path d="M15.083,10.125 L15.083,15.166 L10,15.166 L10,16 L15.917,16 L15.917,10.125 L15.083,10.125 Z"
                class="si-glyph-fill"></path>
              <path d="M5.917,15.166 L0.834,15.166 L0.834,10.125 L0,10.125 L0,16 L5.917,16 L5.917,15.166 Z"
                class="si-glyph-fill"></path>
            </g>
          </g>
        </svg>
      </a>

      <a class="nav-link" @click.prevent="logOut">
        <svg fill="#000000" height="800px" width="800px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve">
          <title>Выйти</title>
          <path d="M30.9,13.6c-0.1-0.1-0.1-0.2-0.2-0.3l-4-4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.3,2.3H22v-3V3c0-0.6-0.4-1-1-1H4
                    c0,0,0,0,0,0C3.9,2,3.7,2,3.6,2.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0C3.2,2.4,3.1,2.5,3.1,2.6
                    c0,0,0,0,0,0.1C3,2.8,3,2.9,3,3v22c0,0.4,0.2,0.8,0.6,0.9l9,4C12.7,30,12.9,30,13,30c0.2,0,0.4-0.1,0.5-0.2c0.3-0.2,0.5-0.5,0.5-0.8
                    v-3h7c0.6,0,1-0.4,1-1V15h5.6l-2.3,2.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l4-4c0.1-0.1,0.2-0.2,0.2-0.3
                    C31,14.1,31,13.9,30.9,13.6z M10,21c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4c0-0.6,0.4-1,1-1s1,0.4,1,1V21z M20,10v14h-6V7
                    c0-0.4-0.2-0.8-0.6-0.9L8.7,4H20V10z" />
        </svg>
      </a>
    </div>
  </nav>


  <router-view />

  <div v-if="getThemeKids" class="kids"></div>
  <footer>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <div class="footer-time">
            {{ date }}, {{ localTime }}
          </div>
        </div>
        <div class="col-6">
          <div class="footer-logo">
            <img src="./assets/logo.svg" alt="ГАУ ТО 'МИАЦ'">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from 'moment';
moment.locale("ru")
import { mapActions, mapGetters } from 'vuex'


export default {
  metaInfo: {
    title: 'Default App Title',
    titleTemplate: '%s | vue-meta Example App',
  },
  data() {
    return {
      date: moment().format('DD MMMM YYYY'),
      localTime: "",
      userTheme: "",
      enableThemeKids: "",
      medorg: "",
    }
  },
  computed: {
    ...mapGetters("getData", ["getThemeKids", "getThemeFont"]),
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showUserBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_USER');
      }

      return false;
    },
    checkKidsTheme() {
      if (localStorage.getItem("user-theme") === "kids-theme") {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions("getData", ["PaginationStatus", "StartCounter", "StopCounter"]),
    showLocaleTime: function () {
      let time = this;
      setInterval(function () {
        time.localTime = moment().format('HH:mm:ss');
      }, 1000);
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
      localStorage.removeItem("user");
      sessionStorage.removeItem("departaments");
    },
    fullscreenEnabled() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    },
    setTheme() {
      if (localStorage.getItem("user-theme") != null)
        document.documentElement.className = localStorage.getItem("user-theme");
    },
  },
  updated() {

  },
  beforeMount() {
    const el = document.getElementById("app");
    el.className = this.getThemeFont;

    if (this.currentUser) {
      this.medorg = this.currentUser.medorg
    } else {
      this.medorg = ""
    }
  },
  beforeUpdate() {
    if (this.currentUser) {
      this.medorg = this.currentUser.medorg
    } else {
      this.medorg = ""
    }
  },
  mounted() {
    this.showLocaleTime()
    this.setTheme()
    this.getThemeKids
  }
};
</script>